import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { BookingButton } from "../../components/buttons"

const Index = props => {
  return (
    <Layout {...props}>
      <SEO
        title="恋愛・対人関係カウンセリング、心理カウンセリング東京"
        description="対人関係カウンセリング。人と関わる時に、緊張してしまう、思ったように話せない、親密な関係が作れないなどで、悩んでいる人は少なくありません。自分のしたことや言ったことを思い出しては、いつもくよくよ悩んでしまう人もいます。人が自分のことを見下しているような気がしたり、非難しているような気がして、ゆううつになってしまう人もいます。"
      />
      <section className="py-6 md:py-16 bg-image bg-image-plant">
        <div className="container">
          <div className="lg:flex">
            <div className="lg:w-2/3">
              <h1 className="page-title b-line">対人関係カウンセリング</h1>
              <p>
                人と関わる時に、緊張してしまう、思ったように話せない、親密な関係が作れないなどで、悩んでいる人は少なくありません。自分のしたことや言ったことを思い出しては、いつもくよくよ悩んでしまう人もいます。人が自分のことを見下しているような気がしたり、非難しているような気がして、ゆううつになってしまう人もいます。
                人間関係は、日々の生活はもちろん、人生を左右する大きな問題の一つともなります。人間関係がうまくいかないと、精神的にもブルーな気分になりやすくなります。何をしても気持ちが晴れず、常に重荷を背負っているような気がして、自分らしく生きるのが難しく感じることもあるでしょう。こちらでは、人間関係がうまくいかず悩んでいる方のお話を聴き、問題を整理し、どのような考え方や対応をすればうまくいくのかを一緒に考えていきます。
              </p>
              <h3>職場の対人関係</h3>
              <p>
                1日の大半を費やす職場の人間関係に問題があるのは、つらいものです。人間関係は職場のストレスの中でも、大きな割合を占めています。無理難題を押し付けてくる上司、うわさ好きな同僚、仕事をしない部下などに対応するには、多くの労力を必要とします。対処できているうちはいいのですが、あまりにストレスが大きくなると、感情を爆発させてしまったり、どうしても体が言うことをきかず、仕事に行けなくなってしまうこともあります。自分がうまく伝えられないために、誤解が生じてしまっていることもあります。
                そうなる前に、職場の人間関係を洗い出し、どのようなコミュニケーションを取れば、事態がスムーズに運ぶかを考えたり、自分が負担を引き受けすぎている場合には、上手な自己主張の仕方（アサーション）を学ぶことが役に立ちます。
                他にも、状況の見方やとらえ方を変えることでも、気持ちが楽になることもあります。セクハラやパワハラなど、明らかに相手側の問題によって、被害に遭っているというケースについてもご相談に乗ります。
              </p>

              <h3>友情関係</h3>
              <p>
                つらいときや困ったときに力になってもらえる友人というのは、かけがえのないものです。それが対等で居心地の良いものであるときはいいのですが、友情が壊れるのを恐れて、いつも我慢していたり、合わせ過ぎたりというアンバランスなものであるならば、付き合い方を見直す必要があります。
                ママ友との関係やお稽古のグループなど、仲間はずれにされたくなくて、つい無理して合わせてしまう、誘われるとうまく断ることができなくて、しんどくなってしまうこともあるでしょう。
                カウンセラーと話し合いながら、その友人が、自分にとって、本当に大切な人であるのかを見直したり、自分にとって居心地の良い友人関係を築く方法を見つけましょう。
              </p>

              <h3>親との関係</h3>
              <p>
                親、特に母親との関係に悩んで相談に来られる方は少なくありません。親との関係というのは、非常に複雑です。親からの影響が大きく、コントロールされ続けてきた場合、それを解決するのは容易ではありません。産み育ててくれた母親を嫌ったり憎んだりすることに罪悪感を覚え、怒りを抑圧して、嫌々ながら関わりを続けているという人もいます。顔を合わせるたびにモヤモヤとした気持ちを抱えたり、ケンカになってしまうということもあります。親との関係での悩みは、周りの人に理解してもらいづらいところがあり、誰にも言えず、一人で悩んでいる人が多いものです。
                また、人間関係がうまくいかない、人を信用できない、人が怖いと感じる背景に、親との関係が未解決のまま残っているということがしばしばあります。人が最初に関わる対人関係は親との関係になりますので、その関係が他の人間関係にも影響を及ぼしてしまうのです。
                カウンセリングでは、幼少期からの親との関係を見直し、どのような影響を受けてきたかを明らかにし、親の問題と自分の問題を分け、本当の意味での親からの自立の道を探っていきます。
              </p>
              <p>
                人間関係の問題は、うまくいかないやり方に固執してしまったり、自分自身を追い込んでしまいがちです。カウンセラーと話し合うことで、視野が広がり、状況を客観的にとらえたり、別のアプローチを考えたりすることができます。自分が無理をしないでいられる人間関係とはどういうものかを、カウンセリングを通して、一緒に見つけましょう。
              </p>
              <div className="text-center md:text-left">
                <BookingButton />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Index
